.cssbuttons-io-button {
    background: var(--green);
    color: var(--black);
    padding: 0.35em;
    padding-left: 2.4rem;
    font-size: 1.6em;
    border-radius: 0.9em;
    border: none;
    letter-spacing: 0.05em;
    display: flex;
    align-items: center;
    box-shadow: inset 0 0 1.6em -0.6em var(--green),
    7px 7px 20px 0px rgba(0,0,0,.1),
    4px 4px 5px 0px rgba(0,0,0,.1);
    overflow: hidden;
    position: relative;
    height: 4.2rem;
    width: 18rem;
    padding-right: 3.3em;
    cursor: pointer;
    font-weight: 700;
    white-space: pre;
    transition: all 0.3s ease;
}

.cssbuttons-io-button .icon {
    background: white;
    margin-left: 1em;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.2em;
    width: 2.2em;
    border-radius: 0.7em;
    box-shadow: 0.1em 0.1em 0.6em 0.2em var(--green);
    right: 0.3em;
    transition: all 0.3s;
}

body.dark .cssbuttons-io-button .icon {
    background: rgb(14, 12, 12);
}

.cssbuttons-io-button:hover .icon {
    width: calc(100% - 0.6em);
}

.cssbuttons-io-button .icon svg {
    width: 1.1em;
    transition: transform 0.3s;
    color: var(--green);
}

.cssbuttons-io-button:hover .icon svg {
    transform: translateX(0.1em);
}

.cssbuttons-io-button:active .icon {
    transform: scale(0.95);
}